import { DataService } from "services/data-service";
import { autoinject } from "aurelia-framework";
import * as moment from "moment";

@autoinject
export class AdminSupplyOrders {
	private _dataService: DataService;

	displaySupplies: any = [];
	stateSupplies: any = [];
	supplyOrders: any = [];

	currentTab: "Supply Orders" | "Level Definitions" = "Supply Orders";

	constructor(dataService: DataService) {
		this._dataService = dataService;
	}

	async activate() {
		this._dataService.getSupplies({}).then((supplies) => {
			this.displaySupplies = JSON.parse(JSON.stringify(supplies));
			this.stateSupplies = JSON.parse(JSON.stringify(supplies));
		});

		this._dataService
			.getSupplyOrders({})
			.then((supplyOrders) => (this.supplyOrders = supplyOrders));
	}

	changeTab(tabName: "Supply Orders" | "Level Definitions") {
		this.currentTab = tabName;
		console.log("Supplies", this.displaySupplies);
		console.log("Supply Orders", this.supplyOrders);
	}

	fillOrder(supplyOrder) {
		if (supplyOrder.status == "Pending") return;
	}

	formatDate(supplyOrder) {
		return moment(supplyOrder.supplyOrderDateTime).format("MMM-DD-YYYY");
	}

	compareSuppliesWithState(index) {
		console.log("Index", index);
		if (
			this.stateSupplies[index].level1Amount !=
			this.displaySupplies[index].level1Amount ||
			this.stateSupplies[index].level2Amount !=
			this.displaySupplies[index].level2Amount ||
			this.stateSupplies[index].level3Amount !=
			this.displaySupplies[index].level3Amount ||
			this.stateSupplies[index].level4Amount !=
			this.displaySupplies[index].level4Amount ||
			this.stateSupplies[index].level5Amount !=
			this.displaySupplies[index].level5Amount
		) {
			this.displaySupplies[index].isChanged = true;
		} else {
			this.displaySupplies[index].isChanged = false;
		}
	}

	async saveLevels() {
		const suppliesToSave = [];

		this.displaySupplies.forEach((supply) => {
			if (supply.isChanged) {
				delete supply.isChanged;
				suppliesToSave.push(supply);
			}
		});

		console.log("Supplies to save", suppliesToSave);
		await this._dataService.saveSupplyLevels(suppliesToSave);

		const supplies = await this._dataService.getSupplies({});
		this.displaySupplies = JSON.parse(JSON.stringify(supplies));
		this.stateSupplies = JSON.parse(JSON.stringify(supplies));
	}

	async downloadSupplyOrderPdf(supplyOrder) {
		const pdf = await this._dataService.downloadSupplyPdf(
            supplyOrder.supplyOrderId
        );

        var url = window.URL.createObjectURL(pdf);

        // Create a link element to trigger the download
        var a = document.createElement("a");
        a.href = url;
        a.download = `supply-order-${supplyOrder.supplyOrderId}.pdf`; // Set the desired file name
        document.body.appendChild(a);

        // Trigger a click event on the link element to initiate the download
        a.click();

        // Clean up by revoking the blob URL and removing the link element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // console.log("result", result);
	}
}
