import { Router } from 'aurelia-router';
import { DataService } from 'services/data-service';
import { autoinject } from 'aurelia-framework';
import Swal from 'sweetalert2'


@autoinject
export class SupplyOrderForm {

	private _dataService: DataService
	private _router: Router;


	client: any;
	supplies = []

	supplyItemsLeft = []
	supplyItemsRight = []

	other: string = ""
	orderedBy: string = ""

	isLoading: boolean = false;
	step: number = 1;

	constructor(dataService: DataService, router: Router) {
		this._dataService = dataService;
		this._router = router;
	}

	async activate() {
		this.isLoading = true

		var clientPromise = this._dataService.getClient(null);
		var suppliesPromise = this._dataService.getSupplies(null);

		Promise.all([clientPromise, suppliesPromise]).then((results) => {

			console.log(results);

			this.client = results[0];
			this.supplies = results[1];

			var leftArray = [
				{ category: "SPECIMIN TUBES", supplyOrderItems: [] },
				{ category: "OTHER SPECIMEN TUBES", supplyOrderItems: [] },
				{ category: "HISTOPATHOLOGY", supplyOrderItems: [] }
			];

			var rightArray = [
				{ category: "MICROBIOLOGY", supplyOrderItems: [] },
				{ category: "MISCELLANEOUS", supplyOrderItems: [] },
				{ category: "COURIER SHIPPING MATERIALS", supplyOrderItems: [] }
			];

			this.setupSupplyItems(leftArray, rightArray);

			this.supplyItemsLeft = leftArray;
			this.supplyItemsRight = rightArray;

			this.isLoading = false;
			//viewmodel.processLimits();
		})
	}
	deactivate() {
		if (this.step == 3)
			this.clearSupplyOrder();

		return true;
	}

	setupSupplyItems(leftArray, rightArray) {
		console.log(this.supplies);

		for (var supplyIndex = 0; supplyIndex < this.supplies.length; supplyIndex++) {
			var supply = this.supplies[supplyIndex];

			if (supply.ignoreOnForm)
				continue;

			var supplyCategory;

			for (var leftIndex = 0; leftIndex < leftArray.length; leftIndex++) {
				if (leftArray[leftIndex].category == supply.category)
					supplyCategory = leftArray[leftIndex];
			}

			for (var rightIndex = 0; rightIndex < rightArray.length; rightIndex++) {
				if (rightArray[rightIndex].category == supply.category)
					supplyCategory = rightArray[rightIndex];
			}

			var limit;

			switch (this.client.supplyClientLevel) {
				case 5:
					limit = supply.level5Amount;
					break;
				case 4:
					limit = supply.level4Amount;
					break;
				case 3:
					limit = supply.level3Amount;
					break;
				case 2:
					limit = supply.level2Amount;
					break;
				case 1:
				default:
					limit = supply.level1Amount;
			}


			supplyCategory.supplyOrderItems.push({
				supplyItemId: supply.supplyItemId,
				name: supply.formName,
				smallText: supply.smallText,
				units: supply.units,
				quantity: "",
				limit: limit
			});
		}
	}
	clearSupplyOrder() {

		this.supplyItemsLeft = [];
		this.supplyItemsRight = [];

		var leftArray = [
			{ category: "SPECIMEN TUBES", supplyOrderItems: [] },
			{ category: "OTHER SPECIMEN TUBES", supplyOrderItems: [] },
			{ category: "HISTOPATHOLOGY", supplyOrderItems: [] }
		];

		var rightArray = [
			{ category: "MICROBIOLOGY", supplyOrderItems: [] },
			{ category: "MISCELLANEOUS", supplyOrderItems: [] },
			{ category: "COURIER SHIPPING MATERIALS", supplyOrderItems: [] }
		];


		this.setupSupplyItems(leftArray, rightArray);

		this.supplyItemsLeft = leftArray;
		this.supplyItemsRight = rightArray;

	}

	public saveSupplyOrder() {
		var supplyOrderItems = this.supplyItemsLeft;

		supplyOrderItems = supplyOrderItems.concat(this.supplyItemsRight);

		var limitExceeded = false;

		for (var supplyOrderItemIndex = 0; supplyOrderItemIndex < supplyOrderItems.length; supplyOrderItemIndex++) {
			var supplyOrderItem = supplyOrderItems[supplyOrderItemIndex];

			for (var supplyIndex = 0; supplyIndex < supplyOrderItem.supplyOrderItems.length; supplyIndex++) {
				if (supplyOrderItem.supplyOrderItems[supplyIndex].quantity == null)
					continue;

				var parsedInt = parseInt(supplyOrderItem.supplyOrderItems[supplyIndex].quantity);

				if (!isNaN(parsedInt)) {
					supplyOrderItem.supplyOrderItems[supplyIndex].quantity = parsedInt;

					if (supplyOrderItem.supplyOrderItems[supplyIndex].quantity > supplyOrderItem.supplyOrderItems[supplyIndex].limit)
						limitExceeded = true;
				}
			}
		}

		console.log(supplyOrderItems);

		if (!limitExceeded) {
			this.step = 2;

			var supplyOrderItemsToSubmit = []

			supplyOrderItems.forEach((supplyOrderItem) => {
				supplyOrderItem.supplyOrderItems.forEach((supplyOrderItem) => {
					supplyOrderItem.quantity = supplyOrderItem.quantity == "" ? 0 : supplyOrderItem.quantity;
					supplyOrderItemsToSubmit.push(supplyOrderItem)
				})
			});

			console.log(supplyOrderItemsToSubmit)

			var supplyOrder = {
				supplyOrderItems: supplyOrderItemsToSubmit,
				other: this.other
			};

			this._dataService.createSupplyOrder(supplyOrder)
				.then(() => {
					this.step = 3
				});
		} else {

			Swal.fire({
				icon: "error",
				title: 'Limit Exceeded',
				text: "You have exceeded your limit for an item. Please either reduce the quanitity ordered or contact True North Veterinary Diagnostics.",
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok'
			})

			return;
		}

	}

	returnHome() {
		this.clearSupplyOrder();
		this.step = 1

		this._router.navigateToRoute("");
	}
}