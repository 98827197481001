import { ICourierRouteSheet } from "interfaces/entity-interfaces";

export class PrintCourierSheet {
    routeShortId: string;

    courierRouteSheet: ICourierRouteSheet;

    constructor() { }

    async activate(parameters) {

        this.routeShortId = parameters.routeShortId;

        const returnedData = await fetch(`/CourierRouteSheets/GetCourierRouteSheet?routeShortId=${this.routeShortId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                //'Authorization': `Bearer ${this._authenticationService.session.token}`

            }
        });

        this.courierRouteSheet = await returnedData.json();

        console.log(this.courierRouteSheet);

    }
    attached() { }

    // editCourierStatus(courierStatus) {
    //     courierStatus.isEditing = true;
    // }

    // toggleGoOutOnRoute(courierStatus) {
    //     courierStatus.goingOutOnRoute = !courierStatus.goingOutOnRoute;
    // }
}