import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { DataService } from "services/data-service";
import * as moment from "moment";
import Swal from "sweetalert2";

@autoinject
export class ReportQueue {
    private _dataService: DataService;

    reportQueueItems: any[] = [];
    isLoadingData: boolean = false;

    constructor(dataService: DataService) {
        this._dataService = dataService;
    }

    activate() {
        this.getReportQueue();
    }

    async getReportQueue() {
        this.isLoadingData = true;
        this.reportQueueItems = await this._dataService.getReportQueue(null);
        this.isLoadingData = false;
    }

    formatDate(date: Date) {
        return moment(date).format("D-MMM-YYYY h:mm:ss a");
    }

    @computedFrom("reportQueueItems")
    get isQueueStuck() {
        var firstProcessedIndex = this.reportQueueItems.findIndex(
            (item) => item.processed
        );

        var lastUnprocessedIndex = this.reportQueueItems
            .slice()
            .reverse()
            .findIndex((item) => !item.processed);

        if (firstProcessedIndex < lastUnprocessedIndex) return true;

        return false;
    }

    deleteQueueItem(reportQueueItem) {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            html: `<p>This will delete the review queue item.</p><p>Accession ID: ${reportQueueItem.accession}</p><p> Unit ID: ${reportQueueItem.unitId}</p>`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
        }).then(async (result) => {
            if (result.value) {
                await this._dataService.deleteReportQueueItem(reportQueueItem);
                await this.getReportQueue();
            }
        });
    }
}
