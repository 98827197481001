import { autoinject } from 'aurelia-framework';
import { DataService } from './../services/data-service';
import { ICourierRouteSheet } from "interfaces/entity-interfaces";
import FlatPickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import * as moment from 'moment';
import { computedFrom } from "aurelia-binding";


@autoinject
export class PrintCourierSheet {
	private _dataService: DataService;

	routeShortId: string;

	courierRouteSheets: Array<ICourierRouteSheet>;

	flatPickerObject: FlatPickr.Instance;

	currentDashboardDate: Date;


	constructor(dataService: DataService) {
		this.currentDashboardDate = moment().toDate();
		this._dataService = dataService;
	}

	async activate() {

		this.fetchCourierRouteSheetsAsync();

	}
	attached() {
		const datepickerElement = document.getElementById("courier-dashboard-datepicker");

		this.flatPickerObject = FlatPickr(datepickerElement, {
			altInput: true,
			defaultDate: this.currentDashboardDate,
			altFormat: "F j, Y",
			maxDate: "today",
			onChange: (newDate) => {
				console.log(newDate);
				this.currentDashboardDate = newDate[0];
				this.fetchCourierRouteSheetsAsync();

			}
		});
	}

	@computedFrom('currentDashboardDate')
	get formattedCurrentDashboardDate(): string {
		return moment(this.currentDashboardDate).format("MMMM D, YYYY")

	}

	async fetchCourierRouteSheetsAsync() {
		this.courierRouteSheets = await this._dataService.getCourierRouteSheets({ date: moment(this.currentDashboardDate).format("YYYYMMDDHHmmss") });
	}

	toggleDatePicker() {
		this.flatPickerObject.toggle();
	}
}