import { DialogService } from "aurelia-dialog";
import { DataService } from "services/data-service";
import { autoinject, TaskQueue } from "aurelia-framework";
import { IClient } from "interfaces/entity-interfaces";
import { AuthenticationService } from "services/authentication-service";
import { Router } from "aurelia-router";
import * as JsBarcode from "jsbarcode";

@autoinject
export class PrintLabRequisition {
    private _dataService: DataService;
    private _authenticationService: AuthenticationService;
    router: Router;

    accession: any;
    accessionId: any;
    client: IClient;

    requisitionStep:
        | "Select Patient"
        | "Patient Details"
        | "Review Requisition" = "Select Patient";

    constructor(
        dataService: DataService,
        authenticationService: AuthenticationService,
        router: Router
    ) {
        this._dataService = dataService;
        this._authenticationService = authenticationService;
        this.router = router;
    }

    async attached() {
        this.client = this._authenticationService.client;

        JsBarcode("#accession-barcode", this.accessionId, {
            displayValue: false,
            height: 40,
            width: 2,
        });
    }

    async activate(params) {
        console.log("Params", params);

        this.accessionId = params.id;
        this.accession = await this._dataService.getAccession(this.accessionId);
    }

    getPatientSex(string) {
        switch (string) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            default:
                break;
        }
    }
    getPatientNeutered(sex, neutered) {
        if (sex == "M") {
            if (neutered == "N") {
                return "Unneutered";
            } else if (neutered == "Y") {
                return "Neutered";
            } else {
                return "Unknown";
            }
        }
        if (sex == "N") {
            if (neutered == "N") {
                return "Unspayed";
            } else if (neutered == "Y") {
                return "Spayed";
            } else {
                return "Unknown";
            }
        }
    }

    returnToDashboard() {
        this.router.navigateToRoute("dashboard");
    }

    ////////Utility Functions////////
    formatDisplayText(wordToDisplay: string) {
        const words = wordToDisplay.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].toLowerCase();
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }

        return words.join(" ");
    }

    printRequisition() {
        window.print();
    }
}
