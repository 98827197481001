import { PasswordResetModal } from "./../modals/password-reset-modal";
import { AuthenticationService } from "services/authentication-service";
import { DialogService } from "aurelia-dialog";
import { DataService } from "./../services/data-service";
import { autoinject } from "aurelia-framework";
import { FormattingService } from "services/formatting-service";
import { Router } from "aurelia-router";

@autoinject
export class Settings {
    private _dataService: DataService;
    private _authenticationService: AuthenticationService;
    private _dialogService: DialogService;

    router: Router;

    isEditing: boolean = false;
    isLoadingUser: boolean = false;
    showingClientSecret: boolean = false;

    dbUser: any;
    displayUser: any;

    constructor(
        dataService: DataService,
        authenticationService: AuthenticationService,
        router: Router,
        dialogService: DialogService
    ) {
        this._dataService = dataService;
        this.router = router;
        this._authenticationService = authenticationService;
        this._dialogService = dialogService;
    }

    async attached() {
        // this.isLoadingUser = true;

        // this.dbUser = await this._dataService.getUser();
        // this.displayUser = this.dbUser;

        // console.log("Display Patient", this.displayUser);

        // this.isLoadingUser = false;

        this.displayUser = JSON.parse(
            JSON.stringify(this._authenticationService.client)
        );
        console.log(this.displayUser);
    }

    edit() {
        this.isEditing = true;
    }
    save() {
        this.isEditing = false;
    }
    cancel() {
        this.isEditing = false;
    }

    toggleClientSecret() {
        this.showingClientSecret = !this.showingClientSecret;
    }
    openPasswordReset() {
        this._dialogService
            .open({
                viewModel: PasswordResetModal,
                model: {},
                lock: true,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    // this.fetchCourierRouteSheets();
                } else {
                    // this._dataService.changePassword() {}
                }
                console.log(response.output);
            });
    }
}
